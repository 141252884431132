import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import { useContext, useMemo, useState } from 'react';
import { Dropdown, Menu, Button, Avatar, Image as AntdImage, Tooltip } from 'antd';
import { useMemoizedFn, useScroll } from 'ahooks';
import { useGlobalState, IconFont } from '@easygo/components';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import cx from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { TopMenusContext } from '@/context/top-menus';
interface LayoutHeaderOfficeProps {
  beginTop?: number; // 监听的高度
  clsName?: string; // 自定义wrapper样式
}

export function LayoutHeaderOffice({ beginTop, clsName }: LayoutHeaderOfficeProps) {
  const { t } = useTranslation(['common', 'auth', 'enums']);
  const router = useRouter();
  const {
    dispatch,
    state: { session, currState },
  } = useGlobalState();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  // 监听滚动
  const scroll = useScroll(document, (val) => typeof beginTop === 'number');
  const targetWapperCls = useMemo(() => {
    setUserDropdownOpen(false);
    if (beginTop && scroll?.top) {
      return scroll.top >= beginTop ? 'showWrapper' : scroll?.top > 56 ? 'hideWrapper' : '';
    } else {
      return '';
    }
  }, [beginTop, scroll]);

  const toHome = () => {
    if (router.asPath !== '/') {
      router.push('/', undefined, { locale: router.locale });
    }
  };
  const toLogin = () => {
    window.open(`/manager/login`, '_blank');
  };

  const toggleLanguage = useMemoizedFn(() => {
    Cookies.set(process.env.NEXT_PUBLIC_LANGUAGE, router.locale === 'zh' ? 'en' : 'zh');
    // router.reload();
    // router.replace(router.asPath, undefined, { locale: Cookies.get(process.env.NEXT_PUBLIC_LANGUAGE) });
    // router.reload();
    // router.replace(router.asPath, undefined, { locale: router.locale });
    // router.reload();
    location.replace(`/${Cookies.get(process.env.NEXT_PUBLIC_LANGUAGE)}/${router.asPath}`);
  });
  const languageTip = useMemo<string | undefined>(() => {
    return router.locale === 'zh' ? i18n?.t('切换为英文') : i18n?.t('切换为中文');
  }, [router.locale]);

  const userMenuRender = useMemo(
    () => (
      <div className={styles.userOverlay}>
        <div className={styles.uoBtns}>
          <div className={styles.uoBtn}>
            <div>{session?.name}</div>
          </div>
          <p className={styles.line} />
          <div
            className={styles.uoBtn}
            onClick={() => {
              dispatch({ type: 'LOGIN_OUT' });
              Cookies.remove(process.env.NEXT_PUBLIC_SESSION_KEY);
              Cookies.remove(process.env.NEXT_PUBLIC_ENTERPRISE_TOKEN_KEY);
              sessionStorage.removeItem(`${process.env.NEXT_PUBLIC_CACHE_PREFIX}user`);
              sessionStorage.removeItem(`${process.env.NEXT_PUBLIC_CACHE_PREFIX}bussiness`);
              sessionStorage.removeItem(`${process.env.NEXT_PUBLIC_CACHE_PREFIX}buss`);
              router.push('/', undefined, { locale: router.locale });
            }}
          >
            <IconFont type="easygo-icon-SignOut" />
            <div>{t('nav_signout')}</div>
          </div>
        </div>
      </div>
    ),

    [t, dispatch, router, session]
  );

  const topMenus = useContext(TopMenusContext);
  const topMenuActive = useMemo(() => topMenus.find((it) => it.key === router.pathname)?.key, [router.pathname, topMenus]);
  return (
    <header
      className={` ${clsName} ${cx(styles.wrapper, {
        [styles.hideWrapper]: targetWapperCls === 'hideWrapper',
        [styles.showWrapper]: targetWapperCls === 'showWrapper',
      })}`}
    >
      <div className={styles.logo} onClick={() => toHome()}>
        <Image src="/images/logo.png" alt="easygo" width={90} height={17} layout="intrinsic" />
      </div>
      <Menu
        items={topMenus}
        mode="horizontal"
        selectedKeys={[topMenuActive!]}
        onClick={({ key }) => {
          key && router.push(key);
        }}
        style={{ flex: 'auto', minWidth: 0 }}
        triggerSubMenuAction="click"
      />

      <div className={styles.navRight}>
        <div className={styles.language}>
          <Tooltip title={languageTip} placement="bottom" showArrow={false}>
            <IconFont type="easygo-icon-Public" style={{ fontSize: 24, color: '#fff' }} onClick={toggleLanguage} />
          </Tooltip>
        </div>
        {session?.accessToken ? (
          <div className={styles.rbox}>
            <div className={styles.user}>
              <Dropdown
                trigger={['click']}
                open={userDropdownOpen}
                onOpenChange={(open) => {
                  setUserDropdownOpen(open);
                }}
                overlay={userMenuRender}
                placement="bottomRight"
                getPopupContainer={(triggerNode) => triggerNode}
              >
                <div className={styles.unAuthUser}>
                  <Avatar
                    src={session.avatarUrl ? <AntdImage src={session.avatarUrl} alt="" preview={false} /> : null}
                    icon={<IconFont type="easygo-icon-yonghu" />}
                  />
                </div>
              </Dropdown>
            </div>
            <Button
              className={styles.toManager}
              type="primary"
              onClick={() => {
                currState === 'saler'
                  ? window.open(`/manager/common/welcome`, '_blank')
                  : window.open(`/manager/buier/products/rentbox/list`, '_blank');
              }}
            >
              {t('nav_to_manager')}
            </Button>
          </div>
        ) : (
          <div className={styles.rbox}>
            <Button type="primary" onClick={() => toLogin()}>
              {t('nav_login')}
            </Button>
          </div>
        )}
      </div>
    </header>
  );
}
