import { i18n } from '@easygo/i18n';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { apis } from '@/server';
import { useEffect, useMemo, useState } from 'react';
import { BannerPositionType } from '@easygo/utils/src/enums';
import { Tabs } from 'antd';
import { utils } from '@easygo/utils';
import { IconFont, easygoTracker, useGlobalState } from '@easygo/components';
import { LayoutHeaderOffice } from '@/components/layout-header-office';

import styles from './index.module.less';
import BannerSwiper from '@easygo/shared/src/components/banner-swiper';
import { useZoneActivity } from '@easygo/shared/src/hooks';
import { CrossPage } from '@easygo/shared/src/components';
import { OperationId_salesgateway_HomeController_banner } from '@/server/api/salesgatewayHomeController';
import { ignoreI18n } from '@easygo/utils/src/utils';

const RentBoxProducts = dynamic(() => import('../components/sale-center/rentbox'), {
  ssr: false,
});
const OceanProducts = dynamic(() => import('../components/sale-center/marine-cabin'), {
  ssr: false,
});
const RailwayProducts = dynamic(() => import('../components/sale-center/railway'), {
  ssr: false,
});
const BoxProducts = dynamic(() => import('../components/sale-center/back-box'), {
  ssr: false,
});
const GoodsProducts = dynamic(() => import('../components/sale-center/back-goods'), {
  ssr: false,
});

export default function Page() {
  const {
    state: { session },
  } = useGlobalState();
  useEffect(() => {
    // 埋点
    easygoTracker('enter_easygo_sale', ignoreI18n({ is_login: session?.accessToken ? '是' : '否' }));
  }, []);
  const { t } = useTranslation(['common', 'website']);
  const zoneActivity = useZoneActivity(
    apis.default.salesgatewayZoneActivityController.OperationId_salesgateway_ZoneActivityController_getActivityDetail
  );
  const [activeKey, setActiveKey] = useState(BannerPositionType.CONTAINER);
  const tabs = useMemo(
    () => [
      {
        label: (
          <div className={styles['product-layout-label']}>
            <IconFont
              style={{ fontSize: 24, marginRight: 8 }}
              type={activeKey === BannerPositionType.CONTAINER ? 'easygo-icon-zuxiang-heise' : 'easygo-icon-Container'}
            />

            {t('product_tab_rentbox')}
          </div>
        ),

        key: BannerPositionType.CONTAINER,
        children: <RentBoxProducts />,
      },
      {
        label: (
          <div className={styles['product-layout-label']}>
            <IconFont
              style={{ fontSize: 24, marginRight: 8 }}
              type={activeKey === 'back-box' ? 'easygo-icon-zuxiang-heise' : 'easygo-icon-Container'}
            />

            {i18n?.t('集装箱调运')}
          </div>
        ),

        key: BannerPositionType.CONTAINER_BACK,
        children: <BoxProducts />,
      },
      {
        label: (
          <div className={styles['product-layout-label']}>
            <IconFont
              style={{ fontSize: 24, marginRight: 8 }}
              type={activeKey === BannerPositionType.SHIPPING ? 'easygo-icon-haiyun-hei' : 'easygo-icon-ContainerShip'}
            />
            {i18n?.t('海运整箱')}

            {/* {zoneActivity?.activityName ? (
           <div className={styles.activityLabel}>
             <span>{zoneActivity.activityName}</span>
           </div>
          ) : null} */}
          </div>
        ),

        key: BannerPositionType.SHIPPING,
        children: <OceanProducts />,
      },
      {
        label: (
          <div className={styles['product-layout-label']}>
            <IconFont
              style={{ fontSize: 24, marginRight: 8 }}
              type={activeKey === 'cross' ? 'easygo-icon-OnlineShop-Color' : 'easygo-icon-OnlineShop-white'}
            />
            {i18n?.t('跨境电商头程')}
          </div>
        ),

        key: BannerPositionType.OVERSEAS_FIRST_STEPS,
        children: <CrossPage />,
      },
      {
        label: (
          <div className={styles['product-layout-label']}>
            <IconFont style={{ fontSize: 24, marginRight: 8 }} type={activeKey === 'railway' ? 'easygo-icon-tieyuncang-hei' : 'easygo-icon-Train'} />
            {t('product_tab_railroad_cabin')}
          </div>
        ),

        key: BannerPositionType.RAILWAY,
        children: <RailwayProducts />,
      },
      // {
      //   label: (
      //     <div className={styles['product-layout-label']}>
      //       <IconFont
      //         style={{ fontSize: 24, marginRight: 8 }}
      //         type={activeKey === 'back-goods' ? 'easygo-icon-zuxiang-heise' : 'easygo-icon-Container'}
      //       />

      //       {i18n?.t('境外用箱')}
      //     </div>
      //   ),

      //   key: BannerPositionType.OVERSEAS_BOXES,
      //   children: <GoodsProducts />,
      // },
    ],

    [activeKey, t, zoneActivity?.activityName]
  );

  return (
    <div className={styles['sale-center']}>
      <Head>
        <title>{t('seoHead.home.title', { ns: 'website' })}</title>
        <meta name="keywords" content={t('seoHead.home.keywords', { ns: 'website' })} />
        <meta name="description" content={t('seoHead.home.description', { ns: 'website' })} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />
      </Head>
      {/* 用于头部分背景设置 */}
      <LayoutHeaderOffice clsName={styles.headerWrapper} />
      <div className={styles.header} />
      <BannerSwiper type={activeKey} height="232px" req={OperationId_salesgateway_HomeController_banner} />
      <div className={styles['goods-layout']}>
        <Tabs
          centered
          tabBarGutter={80}
          items={tabs}
          className={styles['card-tab']}
          activeKey={activeKey}
          onChange={(activeKey) => {
            setActiveKey(activeKey);
          }}
        />
      </div>
    </div>
  );
}

Page.trackerPageName = utils.ignoreI18n('交易中心');

export const getStaticProps = async (context: NextPageContext) => {
  let props: any = {
    ...(await serverSideTranslations(context.locale!, [
      'auth',
      'common',
      'rentBox',
      'enums',
      'message',
      'ocean',
      'website',
      'cashier',
      'translation',
      'railway',
      'shipping',
    ])),
  };
  return { props };
};
